import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { DEFAULT_TRANSLATION } from '../constants/default-translation';
import { LanguageEnum } from '../../api/testrunner/models/language-enum';
import { PersistenceService } from './persistence.service';
import { TestLanguage } from '../../api/moderated/models/test-language';
import { Translation } from '../models/translation';
import { TRANSLATIONS } from '../constants/translations';
import { TranslationCaption } from '../models/translation-caption';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  selectedTranslation: string;
  translations: Translation[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private persistenceService: PersistenceService,
    private translocoService: TranslocoService
  ) {
    this.selectedTranslation = DEFAULT_TRANSLATION;
    this.translations = TRANSLATIONS;
  }

  setDefaultTranslation(): void {
    const selectedTranslation = this.persistenceService.get('selectedTranslation');
    const defaultTranslation = selectedTranslation || DEFAULT_TRANSLATION;
    this.changeTranslation(defaultTranslation);
  }

  setHtmlLangAttr(lang: string = DEFAULT_TRANSLATION): void {
    this.document.documentElement.lang = lang.toLowerCase();
  }

  changeTranslation(value: LanguageEnum | TestLanguage): void {
    const selectedTranslation = this.translations.find((translation) => translation.value === value);
    if (selectedTranslation) {
      this.translocoService.setActiveLang(selectedTranslation.value);
      //this.selectedTranslation = selectedTranslation.value;
      //this.persistenceService.set('selectedTranslation', selectedTranslation.value);
    }
  }

  getDictionaryLanguages(): TranslationCaption {
    const currentLang = this.translocoService.getActiveLang();
    switch (currentLang) {
      case LanguageEnum.Ru:
        return 'captionRu';
      case LanguageEnum.En:
        return 'captionEn';
      case LanguageEnum.Es:
        // todo: когда появятся переводы для системных словарей на испанском, переделать на captionEs
        return 'captionEn';
      case LanguageEnum.Kz:
        // todo: когда появятся переводы для системных словарей на казахском, переделать на captionKz
        return 'captionRu';
      default:
        return 'captionRu';
    }
  }
}
