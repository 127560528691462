import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../action-types';
import { RoomData } from '../../../api/moderated/models/room-data';
import { RoomParticipantRole } from '../../../api/moderated/models/room-participant-role';
import { Session } from '../../../api/moderated/models/session';
import { SessionChatMessage } from '../../../api/moderated/models/session-chat-message';
import { SessionModeratorData } from '../../../api/moderated/models/session-moderator-data';
import { SessionNote } from '../../../api/moderated/models/session-note';
import { SessionRespondentData } from '../../../api/moderated/models/session-respondent-data';
import { SessionStatus } from '../../../api/moderated/models/session-status';
import { Test } from '../../../api/moderated/models/test';
import { TokenInfo } from '../../../api/moderated/models/token-info';

export const addSessionChatMessage = createAction(
  ActionTypes.ADD_MODERATED_SESSION_CHAT_MESSAGE,
  props<{ messageId: string; body?: SessionChatMessage }>()
);

export const addSessionChatMessageSuccess = createAction(
  ActionTypes.ADD_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS,
  props<{ messageId: string; sessionChatMessage: SessionChatMessage }>()
);

export const addSessionNote = createAction(
  ActionTypes.ADD_MODERATED_SESSION_NOTE,
  props<{ body?: SessionNote }>()
);

export const addSessionNoteSuccess = createAction(
  ActionTypes.ADD_MODERATED_SESSION_NOTE_SUCCESS,
  props<{ sessionNote: SessionNote }>()
);

export const deleteSessionChatMessage = createAction(
  ActionTypes.DELETE_MODERATED_SESSION_CHAT_MESSAGE,
  props<{ messageId: string; sessionId: string }>()
);

export const deleteSessionChatMessageSuccess = createAction(
  ActionTypes.DELETE_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS,
  props<{ messageId: string }>()
);

export const deleteSessionNote = createAction(
  ActionTypes.DELETE_MODERATED_SESSION_NOTE,
  props<{ noteId: string; sessionId: string }>()
);

export const deleteSessionNoteSuccess = createAction(
  ActionTypes.DELETE_MODERATED_SESSION_NOTE_SUCCESS,
  props<{ noteId: string }>()
);

export const getModeratorDataAction = createAction(
  ActionTypes.GET_MODERATED_MODERATOR_DATA,
  props<{ testId: string; sessionId: string; body?: SessionModeratorData }>()
);

export const getModeratorDataSuccessAction = createAction(
  ActionTypes.GET_MODERATED_MODERATOR_DATA_SUCCESS,
  props<{ roomData: RoomData; testId: string; sessionId: string }>()
);

export const getRoomDataAction = createAction(
  ActionTypes.GET_MODERATED_ROOM_DATA,
  props<{ testId: string; sessionId: string }>()
);

export const getRoomDataSuccessAction = createAction(
  ActionTypes.GET_MODERATED_ROOM_DATA_SUCCESS,
  props<{ roomData: RoomData }>()
);

export const getSessionAction = createAction(
  ActionTypes.GET_MODERATED_SESSION,
  props<{ testId: string; sessionId: string }>()
);

export const getSessionSuccessAction = createAction(
  ActionTypes.GET_MODERATED_SESSION_SUCCESS,
  props<{ session: Session }>()
);

export const getTestAction = createAction(
  ActionTypes.GET_MODERATED_TEST,
  props<{ testId: string; tokenInfo?: TokenInfo }>()
);

export const getTestAndRoomDataAndSessionAction = createAction(
  ActionTypes.GET_MODERATED_TEST_AND_ROOM_DATA_AND_SESSION,
  props<{ tokenInfo: TokenInfo; testId: string; sessionId: string }>()
);

export const getTestAndSessionAction = createAction(
  ActionTypes.GET_MODERATED_TEST_AND_SESSION,
  props<{ testId: string; sessionId: string }>()
);

export const getTestSuccessAction = createAction(
  ActionTypes.GET_MODERATED_TEST_SUCCESS,
  props<{ test: Test }>()
);

export const getTokenInfoAction = createAction(
  ActionTypes.GET_MODERATED_TOKEN_INFO,
  props<{ role: RoomParticipantRole; testId: string }>()
);

export const getTokenInfoSuccessAction = createAction(
  ActionTypes.GET_MODERATED_TOKEN_INFO_SUCCESS,
  props<{ tokenInfo: TokenInfo }>()
);

export const getViewerDataAction = createAction(
  ActionTypes.GET_MODERATED_VIEWER_DATA,
  props<{ role: RoomParticipantRole; testId: string; sessionId: string }>()
);

export const initTestAction = createAction(
  ActionTypes.INIT_MODERATED_TEST,
  props<{ role: RoomParticipantRole; testId: string }>()
);

export const setSessionStatusAction = createAction(
  ActionTypes.SET_MODERATED_SESSION_STATUS,
  props<{ testId: string; sessionId: string; status?: SessionStatus }>()
);

export const setSessionStatusSuccessAction = createAction(
  ActionTypes.SET_MODERATED_SESSION_STATUS_SUCCESS,
  props<{ session: Session }>()
);

export const startSessionAction = createAction(
  ActionTypes.START_MODERATED_SESSION,
  props<{ testId: string; body?: SessionRespondentData }>()
);

export const startSessionSuccessAction = createAction(
  ActionTypes.START_MODERATED_SESSION_SUCCESS,
  props<{ roomData?: RoomData | null; session?: Session | null }>()
);

export const updateSessionChatMessage = createAction(
  ActionTypes.UPDATE_MODERATED_SESSION_CHAT_MESSAGE,
  props<{ body?: SessionChatMessage }>()
);

export const updateSessionChatMessageSuccess = createAction(
  ActionTypes.UPDATE_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS,
  props<{ sessionChatMessage: SessionChatMessage }>()
);

export const updateSessionNote = createAction(
  ActionTypes.UPDATE_MODERATED_SESSION_NOTE,
  props<{ body?: SessionNote }>()
);

export const updateSessionNoteSuccess = createAction(
  ActionTypes.UPDATE_MODERATED_SESSION_NOTE_SUCCESS,
  props<{ sessionNote: SessionNote }>()
);

