import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {}

  public static get currentDateTimeUTC(): string {
    return moment.utc().toISOString();
  }

  public static get currentTimestampUTC(): number {
    return moment.utc().valueOf();
  }

  public static get timezone(): number {
    return moment().utcOffset();
  }

  public static convertTimeToMilliseconds(time: string): number {
    return moment.duration(time, 'milliseconds').asMilliseconds();
  }

  public static convertToDateTimeStringUTC(timestamp: number): string {
    return moment.utc(timestamp).toISOString();
  }

  public static getDuration(startDateTime: string = this.currentDateTimeUTC, endDateTime: string = this.currentDateTimeUTC): number {
    return moment(endDateTime).diff(moment(startDateTime));
  }

  public static getTimestampUTC(dateTime: string | number): number {
    return moment.utc(dateTime).valueOf();
  }
}
