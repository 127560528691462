import { LanguageEnum } from '../../api/testrunner/models/language-enum';
import { Translation } from '../models/translation';

export const TRANSLATIONS: Translation[] = [
  {
    label: 'Русский',
    value: LanguageEnum.Ru,
  },
  {
    label: 'English',
    value: LanguageEnum.En,
  },
  {
    label: 'Español',
    value: LanguageEnum.Es,
  },
  {
    label: 'Қазақ',
    value: LanguageEnum.Kz,
  },
];
