import { Environment } from '../app/models/environment';
import { LanguageEnum } from '../api/testrunner/models/language-enum';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://testrunner-api.beta.fabuza.ru',
  apiVersion: 'v1',
  clientLogsApiUrl: 'https://clientlogs-api.beta.fabuza.ru',
  kvStorageApiUrl: 'https://kv-storage.beta.fabuza.ru',
  moderatedApiUrl: 'https://moderated.beta.fabuza.ru',
  telemetryApiUrl: 'https://telemetry-api.beta.fabuza.ru',
  androidAppPageUrl: 'https://play.google.com/store/apps/details?id=ru.fabuza.research',
  iosAppPageUrl: 'https://apps.apple.com/us/app/fabuza-research/id6444706028',
  mobileAppScheme: 'fabuza',
  androidModeratedAppPageUrl: 'https://play.google.com/store/apps/details?id=ru.fabuza.research',
  iosModeratedAppPageUrl: 'https://apps.apple.com/us/app/fabuza-research/id6444706028',
  mobileModeratedAppScheme: 'fabuzamoder',
  extensionUrl: 'https://chrome.google.com/webstore/detail/oocgmediflfkgeccpdiibnkjofldalfh',
  logo: '/assets/images/logos/fabuza.svg',
  favicon: '/assets/favicons/fabuza/favicon.ico',
  language: LanguageEnum.Ru,
};